export default {
  methods: {
    showErrorDuplicateName() {
      this.$message.showError('duplicateCategory');
    },
    checkExistCategoryName(categoryName, categoryList) {
      return categoryList?.some((item) => item.name === categoryName);
    },
    async handleUpdateCategory(id, name, index, categoryList, callback = null) {
      this.$message.reset();
      if (name === categoryList[index].name) {
        return;
      } else if (this.checkExistCategoryName(name)) {
        this.errors[`categoryName${index}`] = true;
        this.showErrorDuplicateName();
        this.formData[index].name = categoryList[index].name;
      } else if (name === '') {
        this.errors[`categoryName${index}`] = true;
        this.formData[index].name = categoryList[index].name;
        this.$message.showError('noCategoryName');
      } else {
        if (callback) callback(id, name);
      }
    },
    async handleSortCategory(value, element = '', item = '', categoryList, action, hasShopId = true) {
      this.$message.reset();
      let newIndexList = [];
      categoryList.forEach((el) => {
        newIndexList.push(el[element]);
      });
      let index;
      if (value.moved.newIndex < value.moved.oldIndex)
        index = value.moved.newIndex + 1;
      else index = value.moved.newIndex - 1;
      const data = {
        id: value.moved.element.id,
        [item]: newIndexList[index],
      };
      const result = await this.$store.dispatch(action, {
        subdomain: this.subdomain,
        ...(hasShopId ? { shopId: this.shopId } : {}),
        data: data,
      });
      this.getCategories();
      if (result) this.$message.updated('category');
    }
  },
}
