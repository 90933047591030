import { template, templateSettings } from 'lodash';
import { LABEL_MAP, MESSAGE_MAP } from '@/config/message';
import store from '@/store';
import { mapGetters } from 'vuex';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export default {
  data: function() {
    return {
      errors: {},
      prefixs: [],
    };
  },
  computed: {
    ...mapGetters({
      errorFields: 'common/errorFields',
      eventErrorFields: 'event/errorFields',
    })
  },
  created() {
    this.initialErrors();
  },
  watch: {
    errorFields(val) {
      if (!Object.keys(this.eventErrorFields).length) {
        this.resetErrors(this.errors);
      }
      this.errors = { ...this.errors, ...val };
    },
    eventErrorFields(val) {
      this.resetErrors(this.errors);
      this.errors = { ...this.errors, ...val };
    },
  },
  methods: {
    initialErrors() {
      this.prefixs.forEach(item => this.errors[item] = {});
      this.errors = { ...this.errors, ...this.eventErrorFields };
    },
    resetErrors(list) {
      Object.keys(list).forEach(key => {
        if (typeof list[key] === 'object') {
          this.resetErrors(list[key])
        } else {
          list[key] = false;
        }
      })
    },
    getErrors(validators, prefix, customValidators = false) {
      this.$message.reset();
      this.errors = {};
      this.prefixs.forEach(item => this.errors[item] = {});
      this.showErrors(validators, prefix, null, customValidators);
    },
    showMessage(params, field, fieldName, prefix, indexInErrorArray) {
      Object.keys(params).forEach((rule) => {
        if (!field[rule]) {
          if(indexInErrorArray)
            this.errors[fieldName + Number.parseInt(indexInErrorArray - 1)] = true;
          else if (this.prefixs.includes(prefix)) {
            this.errors[prefix] = {
              ...this.errors[prefix],
              [fieldName]: true,
            };
          }
          else this.errors[fieldName] = true;
          const compiled = template(MESSAGE_MAP[rule]);
          const label = prefix ? LABEL_MAP[prefix][fieldName] : LABEL_MAP[fieldName];
          let error = '';
          if (indexInErrorArray) {
            error = `${label}（${indexInErrorArray}）${compiled(field.$params[rule])}`;
          } else if (field.$params[rule]?.notLabelInFirst) {
            error = compiled({ ...field.$params[rule], label });
          } else {
            error = `${label}${compiled(field.$params[rule])}`;
          }
          store.dispatch('ADD_ALERTS', {
            message: error,
            type: 'error',
          });
        }
      });
    },
    showErrors(validators, prefix, indexInErrorArray, customValidators) {
      Object.keys(validators.$params).forEach((fieldName) => {
        const field = validators[fieldName];
        if (field.$invalid) {
          if (field.$each && field.$each?.$iter) {
            const withoutEachParams = { ...field.$params };
            delete withoutEachParams.$each;
            if (withoutEachParams && Object.keys(withoutEachParams).length > 0) {
              this.showMessage(withoutEachParams, field, fieldName, prefix, indexInErrorArray);
            }
            const iters = field.$each?.$iter;
            for (const key in iters) {
              const iterChild = iters[key];
              if (iterChild.$model && typeof iterChild.$model === 'object') {
                this.showErrors(iterChild, fieldName, Number.parseInt(key) + (customValidators ? 0 : 1), customValidators);
              } else {
                const params = iterChild.$params;
                for (const childRule of Object.keys(params)) {
                  if (!iterChild[childRule]) {
                    const compiled = template(MESSAGE_MAP[childRule]);
                    const label = prefix ? LABEL_MAP[prefix][fieldName] : LABEL_MAP[fieldName];
                    this.errors[childRule + Number.parseInt(+key + 1)] = true;
                    store.dispatch('ADD_ALERTS', {
                      message: `${label}（${Number.parseInt(key) + 1}）${compiled(params[childRule])}`,
                      type: 'error',
                    });
                  }
                }
              }
            }
          } else if (field.$model && !Array.isArray(field.$model) && typeof field.$model === 'object') {
            this.showErrors(field, fieldName);
          } else {
            this.showMessage(field.$params, field, fieldName, prefix, indexInErrorArray);
          }
        }
      });
    },
  },
};
