export default {
  data: function() {
    return {
      pageNavs: [
        {
          id: '01',
          name: 'カテゴリー一覧',
          path: '/shop/menu/category',
          routeName: 'ShopMenuCategory',
          includes: false,
        },
        {
          id: '02',
          name: 'メニュー一覧',
          path: '/shop/menu/list',
          routeName: 'ShopMenu',
          includes: false,
        },
        {
          id: '03',
          name: 'メニュー登録',
          path: '/shop/menu/register',
          routeName: 'ShopMenuRegister',
          includes: false,
        },
      ],
    };
  },
};
